html, body {
  overflow: auto;
}

html {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  background-color: #1D1D1D;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.video-input {
  color: #6e6e6e;
  background-color: #272727;
  border: none;
  border-radius: 5px;
  padding: 0.25rem;
  padding-left: 0.5rem;
}

.video-input::placeholder {
  color: #474747;
}

.viewer-list {
  color: #666666;
}

.seek-icon:not(:first-child) {
  margin-left: 0.5rem;
}

.seek-icon {
  transition: .2s all ease-in-out;
  color: #696969
}

.seek-icon:hover {
  cursor: pointer;
  color: #878787;
}

input:focus, textarea:focus {
  outline: none;
}

.room-preview {
  transition: .2s all ease-in-out;
}

.room-preview:hover {
  cursor: pointer;
  transform: scale(1.05)
}
