@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600&display=swap');

// Set your brand colors
$blue: #0081A9;
$pink: #FA7C91;
$brown: #757763;
$beige-light: #D0D1CD;
$beige-lighter: #EFF0EB;

// Update Bulma's global variables
$family-primary: 'Nunito', sans-serif;
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $blue;
$link: #DBF6FF;
$link-hover: white;
$widescreen-enabled: false;
$fullhd-enabled: false;
$radius: 2px;

$input-background-color: #272727;
$input-border-color: none;
$input-hover-border-color: none;
$input-hover-color: none;
$input-focus-box-shadow-color: none;
$input-focus-color: none;
$input-focus-border-color: none;

$section-padding: 3rem 6rem;

$modal-card-body-background-color: #1D1D1D;
$modal-card-head-background-color: #1D1D1D;
$modal-card-head-border-bottom: none;
$modal-card-foot-border-top: none;
$modal-card-title-color: #E3E3E3;

@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/helpers/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/elements/button.sass";
@import "../node_modules/bulma/sass/elements/container.sass";
@import "../node_modules/bulma/sass/elements/title.sass";
@import "../node_modules/bulma/sass/form/_all.sass";
@import "../node_modules/bulma/sass/components/navbar.sass";
@import "../node_modules/bulma/sass/components/modal.sass";
@import "../node_modules/bulma/sass/layout/hero.sass";
@import "../node_modules/bulma/sass/layout/section.sass";
@import "../node_modules/bulma/sass/grid/columns.sass";